import {
    CarTypeFilterEnum,
    CarTypeFilterType,
    defaultCarTypeFilterConfig,
    defaultMinMaxSliderFilter,
    defaultMultipleChoiceFilter,
    getDefaultPriceFilter,
    MinMaxSliderFilterConfigType,
    MultipleChoiceFilterConfigType,
    MultipleChoiceValueType,
    PriceFilterConfigType,
} from "../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import {
    MODEL_FILTER_INIT,
    MODEL_FILTER_RESET_CAR_TYPE,
    MODEL_FILTER_RESET_MULTI,
    MODEL_FILTER_RESET_PRICE,
    MODEL_FILTER_RESET_SLIDER,
    MODEL_FILTER_SELECT_MULTI,
    MODEL_FILTER_SET_CAR_TYPE,
    MODEL_FILTER_SET_ACTIVE_FILTERS,
    MODEL_FILTER_SET_PRICE,
    MODEL_FILTER_SET_PRICE_ACTIVE,
    MODEL_FILTER_SET_SLIDER_VALUE,
    MODEL_FILTER_SHOW_MORE,
    MODEL_FILTER_UPDATE_MULTI_COUNT,
    ModelFiltersActionsType,
    MODEL_FILTER_SHOW_FILTER,
} from "../actions/ModelFiltersActions";
import {
    applyMultiValueResultCount,
    resetMultiValue,
    resetPriceFilter,
    resetSlider,
    selectMultiValue,
    setPrice,
    setPriceActive,
    setSliderValue,
} from "../../../../../shared-logic/features/filters/utils/storeHelpers";
import { ModelFilterId, aggregationModelFilterIds } from "../../utils/constants/filterConstants";

export type ModelFiltersReducerType = {
    initialized: boolean;
    showMoreFilters: boolean;
    hasActiveFilters: boolean;
    activeFilters: ModelFilterId[];
    [ModelFilterId.CarType]: CarTypeFilterType;
    [ModelFilterId.Equipment]: MultipleChoiceFilterConfigType;
    [ModelFilterId.Seats]: MinMaxSliderFilterConfigType;
    [ModelFilterId.Doors]: MinMaxSliderFilterConfigType;
    [ModelFilterId.Speed]: MinMaxSliderFilterConfigType;
    [ModelFilterId.PowerOutput]: MinMaxSliderFilterConfigType;
    [ModelFilterId.Luggage]: MinMaxSliderFilterConfigType;
    [ModelFilterId.FuelConsumption]: MinMaxSliderFilterConfigType;
    [ModelFilterId.Co2]: MinMaxSliderFilterConfigType;
    [ModelFilterId.Eta]: MinMaxSliderFilterConfigType;
    [ModelFilterId.OnlineSales]: MultipleChoiceFilterConfigType;
    [ModelFilterId.Price]: PriceFilterConfigType;
    [ModelFilterId.TowingCapacity]: MinMaxSliderFilterConfigType;
    [ModelFilterId.LoadVolume]: MinMaxSliderFilterConfigType;
    [ModelFilterId.MaximumPayload]: MinMaxSliderFilterConfigType;
    [ModelFilterId.Transmission]: MultipleChoiceFilterConfigType;
    [ModelFilterId.Wheeldrive]: MultipleChoiceFilterConfigType;
};

export const modelFiltersReducerInitialState: ModelFiltersReducerType = {
    initialized: false,
    showMoreFilters: false,
    hasActiveFilters: false,
    activeFilters: [],
    [ModelFilterId.CarType]: defaultCarTypeFilterConfig,
    [ModelFilterId.Equipment]: defaultMultipleChoiceFilter,
    [ModelFilterId.Seats]: defaultMinMaxSliderFilter,
    [ModelFilterId.Doors]: defaultMinMaxSliderFilter,
    [ModelFilterId.Speed]: defaultMinMaxSliderFilter,
    [ModelFilterId.PowerOutput]: defaultMinMaxSliderFilter,
    [ModelFilterId.Luggage]: defaultMinMaxSliderFilter,
    [ModelFilterId.FuelConsumption]: defaultMinMaxSliderFilter,
    [ModelFilterId.Co2]: defaultMinMaxSliderFilter,
    [ModelFilterId.Eta]: defaultMinMaxSliderFilter,
    [ModelFilterId.OnlineSales]: defaultMultipleChoiceFilter,
    [ModelFilterId.Price]: getDefaultPriceFilter(),
    [ModelFilterId.TowingCapacity]: defaultMinMaxSliderFilter,
    [ModelFilterId.LoadVolume]: defaultMinMaxSliderFilter,
    [ModelFilterId.MaximumPayload]: defaultMinMaxSliderFilter,
    [ModelFilterId.Transmission]: defaultMultipleChoiceFilter,
    [ModelFilterId.Wheeldrive]: defaultMultipleChoiceFilter,
};

const clearMCValues = <T extends MultipleChoiceValueType>(values: T[]): T[] =>
    values.map((value) => ({ ...value, selected: false }));

const ModelFiltersReducer = (
    state: ModelFiltersReducerType = modelFiltersReducerInitialState,
    action: ModelFiltersActionsType,
): ModelFiltersReducerType => {
    switch (action.type) {
        // ----------------------------------------------------------------------
        // Common actions
        // ----------------------------------------------------------------------
        case MODEL_FILTER_INIT: {
            return {
                ...state,
                ...action.initState,
                initialized: true,
            };
        }

        case MODEL_FILTER_SHOW_MORE: {
            return { ...state, showMoreFilters: action.show };
        }

        case MODEL_FILTER_SET_ACTIVE_FILTERS: {
            return { ...state, activeFilters: action.activeFilters };
        }

        case MODEL_FILTER_SHOW_FILTER: {
            const { show, filterId } = action;
            return { ...state, [filterId]: { ...state[filterId], show } };
        }

        // ----------------------------------------------------------------------
        // Slider actions
        // ----------------------------------------------------------------------
        case MODEL_FILTER_SET_SLIDER_VALUE: {
            const { filterId, valueType, value } = action;
            return { ...state, [filterId]: setSliderValue(state[filterId], valueType, value) };
        }

        case MODEL_FILTER_RESET_SLIDER: {
            const { filterId } = action;
            return { ...state, [filterId]: resetSlider(state[filterId]) };
        }

        // ----------------------------------------------------------------------
        // Multiple choice actions
        // ----------------------------------------------------------------------
        case MODEL_FILTER_SELECT_MULTI: {
            const { filterId, ids, selected } = action;
            return { ...state, [filterId]: selectMultiValue(state[filterId], ids, selected) };
        }

        case MODEL_FILTER_RESET_MULTI: {
            const { filterId } = action;
            return { ...state, [filterId]: resetMultiValue(state[filterId]) };
        }

        case MODEL_FILTER_UPDATE_MULTI_COUNT: {
            const { updates } = action;
            const newState = { ...state };

            // Set car type aggregations.
            const carTypeState: CarTypeFilterType = { ...state[ModelFilterId.CarType] };

            Object.values(CarTypeFilterEnum)
                .filter(
                    (carTypeFilterId) =>
                        updates[ModelFilterId.CarType] && updates[ModelFilterId.CarType]![carTypeFilterId],
                )
                .forEach((carTypeFilterId: CarTypeFilterEnum) => {
                    (carTypeState[carTypeFilterId] as MultipleChoiceValueType[]) = applyMultiValueResultCount(
                        carTypeState[carTypeFilterId],
                        updates[ModelFilterId.CarType]![carTypeFilterId]!,
                    );
                });

            newState[ModelFilterId.CarType] = carTypeState;

            // Set other state aggregations.
            aggregationModelFilterIds
                .filter((filterId) => updates[filterId])
                .forEach((filterId) => {
                    (newState[filterId] as MultipleChoiceFilterConfigType) = {
                        ...newState[filterId],
                        values: applyMultiValueResultCount(newState[filterId].values, updates[filterId]!),
                    };
                });

            return newState;
        }

        // ----------------------------------------------------------------------
        // Price filter
        // ----------------------------------------------------------------------
        case MODEL_FILTER_SET_PRICE: {
            const { filterId, financeOption, priceType, value } = action;
            return { ...state, [filterId]: setPrice(state[filterId], priceType, value, financeOption) };
        }

        case MODEL_FILTER_RESET_PRICE: {
            const { filterId } = action;
            return { ...state, [filterId]: resetPriceFilter(state[filterId]) };
        }

        case MODEL_FILTER_SET_PRICE_ACTIVE: {
            const { filterId, financeOption } = action;
            return { ...state, [filterId]: setPriceActive(state[filterId], financeOption) };
        }

        // ----------------------------------------------------------------------
        // Car type filter actions
        // ----------------------------------------------------------------------
        case MODEL_FILTER_SET_CAR_TYPE: {
            const { filterId, filter, valueIds, selected } = action;

            const newFilter = (state[filterId][filter] as MultipleChoiceValueType[]).map((value) => {
                if (valueIds.includes(value.id)) return { ...value, selected };
                return value;
            });

            return { ...state, [filterId]: { ...state[filterId], [filter]: newFilter, active: true } };
        }

        case MODEL_FILTER_RESET_CAR_TYPE: {
            const { filterId, filter } = action;
            const usedCarType: CarTypeFilterType = { ...state[filterId] };

            if (filter) {
                (usedCarType[filter] as MultipleChoiceValueType[]) = clearMCValues(usedCarType[filter]);
            } else {
                usedCarType.fuelType = clearMCValues(usedCarType.fuelType);
                usedCarType.carType = clearMCValues(usedCarType.carType);
                usedCarType.models = clearMCValues(usedCarType.models);
                usedCarType.active = false;
            }

            return { ...state, [filterId]: usedCarType };
        }

        default:
            return state;
    }
};

export default ModelFiltersReducer;

import { TypedUseSelectorHook, useSelector } from "react-redux";
import { Dispatch, ReducersMapObject, Store } from "redux";
import CommonSettingsReducer from "../../../../common-deprecated/redux/reducers/CommonSettingsReducer";
import modalReducer from "../../../../common-deprecated/redux/reducers/ModalReducer";
import utilReducer from "../../../../common-deprecated/redux/reducers/UtilReducer";
import { getInitStoreByReducers } from "../../../../common-deprecated/redux/storeHelpers";
import { CreateLogicType } from "../../../../common-deprecated/redux/typeHelpers";
import ModelFilterSettingsReducer, { ModelFilterSettingsType } from "./reducers/ModelFilterSettingsReducer";
import { ModelFilterSettingsActionsType } from "./actions/ModelFilterSettingsActions";
import modelFiltersReducer, { ModelFiltersReducerType } from "./reducers/ModelFiltersReducer";
import modelResultsReducer, { ModelResultsReducerType } from "./reducers/ModelResultsReducer";
import { ModelFiltersActionsType } from "./actions/ModelFiltersActions";
import { ModelResultsActionsType } from "./actions/ModelResultsActions";
import modelResultLogic from "./logic/modelResultLogic";
import modelFilterLogic from "./logic/modelfilterLogic";
import RetailerSettingsReducer, {
    RetailerSettingsType,
} from "../../../../shared-logic/features/retailer/redux/reducers/RetailerSettingsReducer";
import { RetailerSettingsActionsType } from "../../../../shared-logic/features/retailer/redux/actions/RetailerSettingsActions";
import { CommonActionsType, CommonReducerType } from "../../../../common-deprecated/redux/commonStore";

export type ModelFilterStateType = CommonReducerType & {
    modelFilters: ModelFiltersReducerType;
    modelResults: ModelResultsReducerType;
    modelFilterSettings: ModelFilterSettingsType;
    retailerSettings: RetailerSettingsType;
};

export type ModelFilterActionsType =
    | CommonActionsType
    | ModelFiltersActionsType
    | ModelResultsActionsType
    | ModelFilterSettingsActionsType
    | RetailerSettingsActionsType;

export type ModelFilterDispatchType = Dispatch<ModelFilterActionsType>;

export type ModelFilterLogicType<ActionsType extends ModelFilterActionsType> = CreateLogicType<
    ActionsType,
    ModelFilterStateType,
    ModelFilterDispatchType
>;

export type ModelFilterStoreType = Store<ModelFilterStateType, ModelFilterActionsType>;

export const useModelFilterSelector: TypedUseSelectorHook<ModelFilterStateType> = useSelector;

export const modelFilterReducers: ReducersMapObject<ModelFilterStateType, any> = {
    modelFilters: modelFiltersReducer,
    modelResults: modelResultsReducer,
    modal: modalReducer,
    modelFilterSettings: ModelFilterSettingsReducer,
    commonSettings: CommonSettingsReducer,
    retailerSettings: RetailerSettingsReducer,
    utils: utilReducer,
};

export const modelFilterServerStore = getInitStoreByReducers(modelFilterReducers);
export const modelFilterClientStore = getInitStoreByReducers(modelFilterReducers, [
    ...modelResultLogic,
    ...modelFilterLogic,
]);

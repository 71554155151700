import React from "react";
import * as ButtonStyles from "../../../styles/v2/toyota/components/Button";

type ButtonProps = {
    href?: string;
    onClick?: (event: any) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    target?: string;
    rel?: string;
    children: React.ReactNode;
    hasIcon?: boolean;
    hasIconRight?: boolean;
    tabIndex?: number;
    stretchedLink?: boolean;
    small?: boolean;
};

/**
 * ButtonQuaternary - Button link style with icon
 * @example
 * <ButtonQuaternary>
 *    <IconWrapper>
 *      <Icon variant="icon" />
 *    </IconWrapper>
 *    Label
 * </ButtonQuaternary>
 */

const ButtonQuaternary = (props: ButtonProps): JSX.Element => {
    const {
        href,
        onClick,
        disabled,
        fullWidth,
        children,
        target,
        rel,
        hasIcon,
        hasIconRight,
        tabIndex,
        stretchedLink,
        small,
    } = props;

    return (
        <ButtonStyles.ButtonQuaternary
            as={href ? "a" : "button"}
            role={href ? "link" : "button"}
            href={href && !disabled ? href : undefined}
            type={!href ? "button" : undefined}
            disabled={!href && disabled}
            aria-disabled={disabled || undefined}
            target={href ? target : undefined}
            rel={href && rel}
            onClick={onClick}
            fullWidth={fullWidth}
            hasIcon={hasIcon}
            hasIconRight={hasIconRight}
            tabIndex={tabIndex}
            stretchedLink={stretchedLink}
            small={small}
        >
            {children}
        </ButtonStyles.ButtonQuaternary>
    );
};

export default ButtonQuaternary;

import styled from "styled-components";
import { ColorType, theme } from "../../../../../styles/v2/toyota/theme/toyotaTheme";

export const Badge = styled.div.attrs(() => ({
    className: "badge",
}))<{ bgColor?: ColorType; color?: ColorType }>`
    display: flex;
    align-items: center;
    padding: 6px ${theme.space[3] / 2}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${({ color }) => color && theme.colors[color]};
    background-color: ${({ bgColor }) => bgColor && theme.colors[bgColor]};
    border-radius: 2.4rem;
    column-gap: ${theme.space[1] / 2}px;
    row-gap: ${theme.space[1] / 2}px;

    & > span {
        position: relative;
        top: -1px;
    }
`;

import React, { ReactNode } from "react";
import { EcoTagType } from "../../../../../../../common-deprecated/types/CommonTypes";
import * as Styles from "./styles/EcoTagsStyles";
import { ecoTagToLabel } from "../../../../../../../common-deprecated/utils/ecoTagUtils";
import {
    getCommonOptionalLabel,
    useCommonOptionalLabel,
} from "../../../../../../../common-deprecated/utils/commonLabels";
import Badge from "../../../../../../../common-deprecated/components/aem/toyota/badge/Badge";
import {
    IconElectric,
    IconEngine,
    IconHybrid,
    IconPetrolDiesel,
    IconPlugin,
} from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { useCommonSelector } from "../../../../../../../common-deprecated/redux/commonStore";

type PropsType = { ecoTags: EcoTagType[]; limit?: number };

const EcoTags = ({ ecoTags, limit = 3 }: PropsType): JSX.Element => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    const multipleEngineOptionsLabel = useCommonOptionalLabel("multipleEngineOptions");

    const getEcoTag = (ecoTag: EcoTagType): { label: string | null; icon: ReactNode; padding: boolean } => {
        const ecoTagLabel = ecoTagToLabel(ecoTag);
        const label = ecoTagLabel ? getCommonOptionalLabel({ commonSettings }, ecoTagLabel) : null;

        let icon: ReactNode;
        let padding = true;

        switch (ecoTag) {
            case EcoTagType.Electric: {
                icon = <IconElectric width={15} />; // 1 symbol, reduce width to 15.
                padding = false;
                break;
            }
            case EcoTagType.Diesel:
            case EcoTagType.Petrol: {
                icon = <IconPetrolDiesel width={17.5} />; // 1 symbol, reduce but fine-tune width to 17.5.
                break;
            }
            case EcoTagType.Hybrid:
            case EcoTagType.FuelCell: {
                icon = <IconHybrid />; // 2 symbols, default width.
                break;
            }
            case EcoTagType.Plugin: {
                icon = <IconPlugin />; // 2 symbols, default width.
                break;
            }
        }

        return { label, icon, padding };
    };

    return (
        <Styles.Wrapper>
            {ecoTags.length === 1 || (ecoTags.length > 1 && ecoTags.length < limit)
                ? ecoTags.map((ecoTag) => {
                      const { label, icon, padding } = getEcoTag(ecoTag);
                      return (
                          label && (
                              <Styles.EcoTag padding={padding} key={label}>
                                  <Badge icon={icon}>{label}</Badge>
                              </Styles.EcoTag>
                          )
                      );
                  })
                : multipleEngineOptionsLabel && (
                      <Styles.EcoTag padding>
                          <Badge icon={<IconEngine />}>{multipleEngineOptionsLabel}</Badge>
                      </Styles.EcoTag>
                  )}
        </Styles.Wrapper>
    );
};

export default EcoTags;

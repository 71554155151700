import styled, { css } from "styled-components";
import { theme } from "../theme/toyotaTheme";
import { IconWrapper } from "../globals/IconWrapper";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import * as TextStyles from "../utilities/Text";
import { focusStyle } from "../utilities/Focus";
import { parseHexToRGB } from "../../../../utils";

/**
 * Pill
 */
export const Pill = styled.div`
    padding: ${theme.space[1] / 2}px ${theme.space[2]}px;
    color: ${theme.colors.primaryWhite};
    background-color: ${theme.colors.primaryBlack};
    border-radius: 20px;
`;

/**
 * Outline Pill
 */
export const OutlinePill = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.space[1] / 2}px ${theme.space[2]}px;
    border: 1px solid rgba(${parseHexToRGB(theme.colors.grey2).rgb}, 0.95);
    border-radius: 20px;
`;

/**
 * Pill Button
 * @example
 * <Pill.Wrapper>
 *   <Pill.Text />
 *   <IconWrapper>
 *     <Icon />
 *   </IconWrapper>
 * </Pill.Wrapper>
 */
type PillType = {
    isPrimary?: boolean;
    hasIcon?: boolean;
    hasIconRight?: boolean;
    iconOnly?: boolean;
    disabled?: boolean;
    isActive?: boolean;
    isSelected?: boolean;
    mobileOnly?: boolean;
};

export const Text = styled.span.attrs(() => ({
    className: "text-body",
}))<{ isActive?: boolean }>`
    max-width: 50px;
    text-overflow: ellipsis;
    color: ${({ isActive }) => (isActive ? theme.colors.primaryWhite : theme.colors.grey4)} !important;
    white-space: nowrap;
    overflow: hidden;
    @media ${getBreakpoint("up", "lg")} {
        max-width: 125px;
    }
`;

export const Wrapper = styled.button<PillType>`
    display: flex;
    position: relative;
    align-items: center;
    height: 48px;
    margin: ${theme.space[1]}px 0;
    padding: ${theme.space[1]}px ${theme.space[2]}px;
    background: ${({ isActive }) => (isActive ? theme.colors.primaryBlack : theme.colors.primaryWhite)};
    border: 1px solid ${({ isActive }) => (isActive ? theme.colors.primaryBlack : theme.colors.grey2)};
    border-radius: 70px;
    cursor: pointer;
    z-index: ${({ isSelected }) => (isSelected ? 1061 : "auto")};

    ${TextStyles.Button} {
        max-width: calc(100% - 32px);
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: ${theme.colors.grey4} !important;
        white-space: nowrap;
    }

    ${({ iconOnly }) =>
        iconOnly &&
        css`
            width: 48px;
            height: 48px;
            padding: 12px;
            border-radius: 50%;
        `};

    ${({ mobileOnly }) =>
        mobileOnly &&
        css`
            @media ${getBreakpoint("up", "lg")} {
                display: none;
            }
        `};

    ${IconWrapper} {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ isActive }) => (isActive ? theme.colors.primaryWhite : theme.colors.grey7)};
        ${({ hasIcon }) =>
            hasIcon &&
            css`
            margin-${getDirection("right")}: ${theme.space[1]}px;
        `};
        ${({ hasIconRight }) =>
            hasIconRight &&
            css`
            margin-${getDirection("left")}: ${theme.space[1]}px;
        `};
    }

    ${focusStyle};
`;

export const Close = styled.div`
    width: 20px;
    height: 20px;
    color: ${theme.colors.primaryBlack};
    transition: color 0.24s;
    &:hover {
        color: ${theme.colors.highlightBlack};
    }
    ${IconWrapper} {
        min-width: 20px;
        margin-top: 2px;
    }
`;

export default Pill;

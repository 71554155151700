import styled from "styled-components";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getBreakpoint, getDirection } from "../../../../../../../../common-deprecated/themes/common";

export const PricesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Cash = styled.div.attrs(() => ({
    className: "t-zeta-text",
}))`
    margin-bottom: 0;
    color: ${theme.colors.grey7};

    > span {
        display: block;
        font-family: ${theme.fonts.base};
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${theme.colors.grey7};

        @media ${getBreakpoint("up", "xl")} {
            font-size: 1.6rem;
        }
    }

    > span:not(:only-child):nth-last-child(1) {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${theme.colors.primaryBlack};

        @media ${getBreakpoint("up", "xl")} {
            font-size: 1.8rem;
        }
    }
`;

export const SpanText = styled.div`
    white-space: nowrap;
`;

export const CashDiscount = styled(Cash)`
    text-decoration: line-through;
`;

export const Monthly = styled.div.attrs(() => ({
    className: "t-milli-text",
}))`
    display: flex;
    align-items: center;
    text-transform: none;
    color: ${theme.colors.grey7};
    span {
        white-space: nowrap;
    }
`;

export const MonthlyPriceDisclaimer = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;
`;

export const DisclaimerIcon = styled.span`
    display: flex;
    align-items: center;
    color: ${theme.colors.grey7};
`;

export const MonthlyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        text-align: ${getDirection("left")};
    }
`;

export const LegalText = styled.p.attrs(() => ({
    className: "t-milli-text",
}))`
    margin: 4px 0 0;
    text-align: ${getDirection("left")};
    text-indent: 0;
    color: ${theme.colors.grey7};
`;

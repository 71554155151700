import styled from "styled-components";
import { theme } from "../../../../../styles/v2/toyota/theme/toyotaTheme";

export const AvailabilityBadges = styled.div.attrs(() => ({
    className: "availability-badges",
}))``;

export const List = styled.ul.attrs(() => ({
    className: "list-unstyled",
}))`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: ${theme.space[1]}px;
    row-gap: ${theme.space[1]}px;
`;

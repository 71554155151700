import { ModelFilterSettingsType } from "../reducers/ModelFilterSettingsReducer";

export const MODEL_FILTER_SETTINGS_INIT = "modelFilterSettings/init";

type InitModelFilterSettings = {
    type: typeof MODEL_FILTER_SETTINGS_INIT;
    initState: ModelFilterSettingsType;
};

export const initModelFilterSettings = (initState: ModelFilterSettingsType): InitModelFilterSettings => ({
    type: MODEL_FILTER_SETTINGS_INIT,
    initState,
});

export type ModelFilterSettingsActionsType = InitModelFilterSettings;

import { ModelFiltersReducerType } from "../reducers/ModelFiltersReducer";
import { FinanceOptionType } from "../../../../../common-deprecated/types/CommonTypes";
import {
    ValueModelResultCountType,
    MinMaxSliderModelFilterIdType,
    MultipleChoiceModelFilterIdType,
    ModelFilterId,
    LcvFilterIdsType,
} from "../../utils/constants/filterConstants";
import { CarTypeFilterEnum } from "../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

// ----------------------------------------------------------------------
// Common
// ----------------------------------------------------------------------
export const MODEL_FILTER_INIT = "modelFilter/init" as const;
export const MODEL_FILTER_SHOW_MORE = "modelFilter/showMore" as const;
export const MODEL_FILTER_SET_ACTIVE_FILTERS = "modelFilter/setActiveFilters" as const;
export const MODEL_FILTER_SHOW_FILTER = "modelFilter/showFilter" as const;

export type ModelFilterShowMoreType = { type: typeof MODEL_FILTER_SHOW_MORE; show: boolean };
export type ModelFilterSetActiveFiltersType = {
    type: typeof MODEL_FILTER_SET_ACTIVE_FILTERS;
    activeFilters: ModelFilterId[];
};
type ModelFilterInitType = {
    type: typeof MODEL_FILTER_INIT;
    initState: Partial<ModelFiltersReducerType>;
};
export type ModelFilterShowFilterType = {
    type: typeof MODEL_FILTER_SHOW_FILTER;
    show: boolean;
    filterId: LcvFilterIdsType;
};

export const initModelFilters = (initState: Partial<ModelFiltersReducerType>): ModelFilterInitType => ({
    type: MODEL_FILTER_INIT,
    initState,
});
export const showMoreFilters = (show: boolean): ModelFilterShowMoreType => ({
    type: MODEL_FILTER_SHOW_MORE,
    show,
});
export const setActiveFilters = (activeFilters: ModelFilterId[]): ModelFilterSetActiveFiltersType => ({
    type: MODEL_FILTER_SET_ACTIVE_FILTERS,
    activeFilters,
});

export const showFilter = (show: boolean, filterId: LcvFilterIdsType): ModelFilterShowFilterType => ({
    type: MODEL_FILTER_SHOW_FILTER,
    show,
    filterId,
});

// ----------------------------------------------------------------------
// Min max slider actions
// ----------------------------------------------------------------------
export const MODEL_FILTER_SET_SLIDER_VALUE = "modelFilter/slider/setValue" as const;
export const MODEL_FILTER_RESET_SLIDER = "modelFilter/slider/reset" as const;

type ModelFilterSetSliderValueType = {
    type: typeof MODEL_FILTER_SET_SLIDER_VALUE;
    valueType: "min" | "max";
    value: number;
    filterId: MinMaxSliderModelFilterIdType;
};
type ModelFilterResetSliderType = {
    type: typeof MODEL_FILTER_RESET_SLIDER;
    filterId: MinMaxSliderModelFilterIdType;
};

export const setSliderValue = (
    valueType: "min" | "max",
    value: number,
    filterId: MinMaxSliderModelFilterIdType,
): ModelFilterSetSliderValueType => ({
    type: MODEL_FILTER_SET_SLIDER_VALUE,
    valueType,
    value,
    filterId,
});
export const resetSlider = (filterId: MinMaxSliderModelFilterIdType): ModelFilterResetSliderType => ({
    type: MODEL_FILTER_RESET_SLIDER,
    filterId,
});

// ----------------------------------------------------------------------
// Multiple choice actions
// ----------------------------------------------------------------------
export const MODEL_FILTER_SELECT_MULTI = "modelFilter/multi/select" as const;
export const MODEL_FILTER_RESET_MULTI = "modelFilter/multi/reset" as const;
export const MODEL_FILTER_UPDATE_MULTI_COUNT = "modelFilter/multi/updateCount" as const;

export type ModelFilterSelectMultiType = {
    type: typeof MODEL_FILTER_SELECT_MULTI;
    ids: string[] | "all";
    selected: boolean;
    filterId: MultipleChoiceModelFilterIdType;
};
type ModelFilterResetMultiType = {
    type: typeof MODEL_FILTER_RESET_MULTI;
    filterId: MultipleChoiceModelFilterIdType;
};
type ModelFilterUpdateMultiCountType = {
    type: typeof MODEL_FILTER_UPDATE_MULTI_COUNT;
    updates: ValueModelResultCountType;
};

export const setSelectMultiFilter = (
    ids: string[] | "all",
    selected: boolean,
    filterId: MultipleChoiceModelFilterIdType,
): ModelFilterSelectMultiType => ({
    type: MODEL_FILTER_SELECT_MULTI,
    selected,
    ids,
    filterId,
});

export const resetMultiFilter = (filterId: MultipleChoiceModelFilterIdType): ModelFilterResetMultiType => ({
    type: MODEL_FILTER_RESET_MULTI,
    filterId,
});

export const updateMultiFilterCounts = (updates: ValueModelResultCountType): ModelFilterUpdateMultiCountType => ({
    type: MODEL_FILTER_UPDATE_MULTI_COUNT,
    updates,
});

// ----------------------------------------------------------------------
// Car type filter actions
// ----------------------------------------------------------------------
export const MODEL_FILTER_SET_CAR_TYPE = "modelFilter/carType/set" as const;
export const MODEL_FILTER_RESET_CAR_TYPE = "modelFilter/carType/reset" as const;

export type ModelFilterSetCarType = {
    type: typeof MODEL_FILTER_SET_CAR_TYPE;
    filterId: ModelFilterId.CarType;
    filter: CarTypeFilterEnum;
    valueIds: string[];
    selected: boolean;
};
export type ModelFilterResetCarType = {
    type: typeof MODEL_FILTER_RESET_CAR_TYPE;
    filterId: ModelFilterId.CarType;
    filter?: CarTypeFilterEnum;
};

// When directly selecting a brand in frontend (React components), do not use this action and use selectAllBrandModels instead.
// This action is still used to set brand select state in related logic though.
export const setCarType = (
    filterId: ModelFilterId.CarType,
    filter: CarTypeFilterEnum,
    valueIds: string[],
    selected: boolean,
): ModelFilterSetCarType => ({
    type: MODEL_FILTER_SET_CAR_TYPE,
    filterId,
    filter,
    valueIds,
    selected,
});
export const resetCarType = (filterId: ModelFilterId.CarType, filter?: CarTypeFilterEnum): ModelFilterResetCarType => ({
    type: MODEL_FILTER_RESET_CAR_TYPE,
    filterId,
    filter,
});

// ----------------------------------------------------------------------
// Price filter
// ----------------------------------------------------------------------
export const MODEL_FILTER_SET_PRICE = "modelFilter/price/setPrice" as const;
export const MODEL_FILTER_RESET_PRICE = "modelFilter/price/reset" as const;
export const MODEL_FILTER_SET_PRICE_ACTIVE = "modelFilter/price/setActive" as const;

export type ModelFilterSetPriceType = {
    type: typeof MODEL_FILTER_SET_PRICE;
    financeOption: FinanceOptionType;
    priceType: "min" | "max";
    value: number;
    filterId: ModelFilterId.Price;
};
export type ModelFilterResetPriceType = { type: typeof MODEL_FILTER_RESET_PRICE; filterId: ModelFilterId.Price };
export type ModelFilterSetPriceActiveType = {
    type: typeof MODEL_FILTER_SET_PRICE_ACTIVE;
    financeOption: FinanceOptionType;
    filterId: ModelFilterId.Price;
};

export const setPrice = (
    filter: ModelFilterId.Price,
    priceType: "min" | "max",
    value: number,
    financeOption: FinanceOptionType,
): ModelFilterSetPriceType => ({
    type: MODEL_FILTER_SET_PRICE,
    financeOption,
    priceType,
    value,
    filterId: filter,
});

export const resetPriceFilter = (filter: ModelFilterId.Price): ModelFilterResetPriceType => ({
    type: MODEL_FILTER_RESET_PRICE,
    filterId: filter,
});

export const setPriceActive = (
    filter: ModelFilterId.Price,
    financeOption: FinanceOptionType,
): ModelFilterSetPriceActiveType => ({
    type: MODEL_FILTER_SET_PRICE_ACTIVE,
    financeOption,
    filterId: filter,
});

// Actions which impact car filter results. Whenever one of the following actions is called new filter results will be retrieved.
export const MODEL_FILTER_ACTIONS = [
    MODEL_FILTER_RESET_CAR_TYPE,
    MODEL_FILTER_SET_CAR_TYPE,
    MODEL_FILTER_SELECT_MULTI,
    MODEL_FILTER_RESET_MULTI,
    MODEL_FILTER_SET_SLIDER_VALUE,
    MODEL_FILTER_RESET_SLIDER,
    MODEL_FILTER_SET_ACTIVE_FILTERS,
    MODEL_FILTER_SET_PRICE,
    MODEL_FILTER_SET_PRICE_ACTIVE,
    MODEL_FILTER_RESET_PRICE,
];

// This array of actions is here only for tracking purposes to determine if the action that triggered a filter update was caused by a "main filter".
// Could not use mainUsedModelFilters and related arrays as we don't always have the filterIds during result refetch.
// This was the easiest workaround without having to completely rework how filter actions work.
export const MODEL_FILTER_MAIN_FILTER_ACTIONS = [
    MODEL_FILTER_RESET_CAR_TYPE,
    MODEL_FILTER_SET_CAR_TYPE,
    MODEL_FILTER_SET_PRICE,
    MODEL_FILTER_SET_PRICE_ACTIVE,
    MODEL_FILTER_RESET_PRICE,
];

export type ModelFiltersActionsType =
    | ModelFilterSetPriceType
    | ModelFilterResetPriceType
    | ModelFilterSetPriceActiveType
    | ModelFilterSetSliderValueType
    | ModelFilterResetSliderType
    | ModelFilterInitType
    | ModelFilterSelectMultiType
    | ModelFilterResetMultiType
    | ModelFilterSetCarType
    | ModelFilterResetCarType
    | ModelFilterShowMoreType
    | ModelFilterSetActiveFiltersType
    | ModelFilterUpdateMultiCountType
    | ModelFilterShowFilterType;

import { CarTypeFilterEnum } from "../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

// ----------------------------------------------------------------------
// Filter id enums
// ----------------------------------------------------------------------

export enum ModelFilterId {
    CarType = "modelType",
    Equipment = "modelEquipment",
    Seats = "modelSeats",
    Doors = "modelDoors",
    Speed = "modelSpeed",
    PowerOutput = "modelPowerOutput",
    Luggage = "modelLuggage",
    FuelConsumption = "modelFuelConsumption",
    Co2 = "modelCo2",
    Price = "modelPrice",
    Eta = "modelEta",
    OnlineSales = "modelOnlineSales",
    TowingCapacity = "modelTowingCapacity",
    LoadVolume = "modelLoadVolume",
    MaximumPayload = "modelMaximumPayload",
    Transmission = "modelTransmission",
    Wheeldrive = "modelWheeldrive",
}

// ----------------------------------------------------------------------
// New car filter mapping
// ----------------------------------------------------------------------
export const CARDB_FILTER_MAPPING = {
    [`${ModelFilterId.Price}-cash`]: "PRICE",
    [`${ModelFilterId.Price}-monthly`]: "MONTHLY_RATE",
    [ModelFilterId.Doors]: "CAP_SLIDER_8",
    [ModelFilterId.Luggage]: "BODY_SLIDE_1",
    [ModelFilterId.Co2]: "ENV_SLIDE_1",
    [ModelFilterId.FuelConsumption]: "ENV_SLIDE_2",
    [ModelFilterId.Seats]: "BODY_SLIDE_2",
    [ModelFilterId.Speed]: "ENG_SLIDE_1",
    [ModelFilterId.PowerOutput]: "ENG_SLIDE_2",
    [ModelFilterId.Equipment]: "EQUIPMENT",
    [ModelFilterId.Eta]: "ETA",
    [ModelFilterId.TowingCapacity]: "CAP_SLIDER_9",
    [ModelFilterId.LoadVolume]: "CAP_SLIDER_2",
    [ModelFilterId.MaximumPayload]: "CAP_SLIDER_1",
    [ModelFilterId.Transmission]: "TRANSMISSION",
    [ModelFilterId.Wheeldrive]: "WHEELDRIVE",
};

// ----------------------------------------------------------------------
// Multiple choice filter ids
// ----------------------------------------------------------------------

export const multipleChoiceModelFilterIds = [
    ModelFilterId.Equipment, // AND FILTER
    ModelFilterId.OnlineSales,
    ModelFilterId.Transmission, // OR FILTER
    ModelFilterId.Wheeldrive, // OR FILTER
] as const;
export type MultipleChoiceModelFilterIdType = (typeof multipleChoiceModelFilterIds)[number];

// ----------------------------------------------------------------------
// Min max slider filter ids
// ----------------------------------------------------------------------

export const lcvFilterIds = [
    ModelFilterId.TowingCapacity,
    ModelFilterId.LoadVolume,
    ModelFilterId.MaximumPayload,
] as const;
export type LcvFilterIdsType = (typeof lcvFilterIds)[number];

export const minMaxSliderModelFilterIds = [
    ModelFilterId.Seats,
    ModelFilterId.Doors,
    ModelFilterId.Luggage,
    ModelFilterId.Speed,
    ModelFilterId.Co2,
    ModelFilterId.FuelConsumption,
    ModelFilterId.PowerOutput,
    ModelFilterId.Eta,
    ModelFilterId.TowingCapacity,
    ModelFilterId.LoadVolume,
    ModelFilterId.MaximumPayload,
] as const;
export type MinMaxSliderModelFilterIdType = (typeof minMaxSliderModelFilterIds)[number];

// ----------------------------------------------------------------------
// Other constants
// ----------------------------------------------------------------------

export enum ModelFilterSortOrder {
    ModelIndex = "modelIndex",
    ModelName = "modelName",
    CashAsc = "cashAsc",
    CashDesc = "cashDesc",
    MonthlyAsc = "monthlyAsc",
    MonthlyDesc = "monthlyDesc",
}

export const aggregationModelFilterIds = [
    ModelFilterId.Equipment,
    ModelFilterId.OnlineSales,
    ModelFilterId.Transmission,
    ModelFilterId.Wheeldrive,
] as const;
export type ModelResultCountType = { valueId: string; resultCount: number };
export type ValueModelResultCountType = Partial<{
    [ModelFilterId.CarType]: Partial<Record<CarTypeFilterEnum, ModelResultCountType[]>>;
    [ModelFilterId.Equipment]: ModelResultCountType[];
    [ModelFilterId.OnlineSales]: ModelResultCountType[];
    [ModelFilterId.Transmission]: ModelResultCountType[];
    [ModelFilterId.Wheeldrive]: ModelResultCountType[];
}>;

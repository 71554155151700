import styled from "styled-components";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { Badge } from "../../../../../../../../common-deprecated/components/aem/toyota/badge/styles/BadgeStyles";

export const EcoTag = styled.span<{ padding: boolean }>`
    font-size: 1.4rem;
    line-height: 1.8rem;

    color: ${theme.colors.hybrid};

    span {
        font-weight: 400;
        text-align: start;
        white-space: normal;
    }

    &:not(:first-child) {
        border-inline-start: 1px solid ${theme.colors.grey2};
        padding-inline-start: ${({ padding }) => padding && theme.space[1] / 2}px;
    }

    &:first-child:not(:only-child) {
        padding-inline-end: ${({ padding }) => padding && theme.space[1] / 2}px;
    }

    ${Badge} {
        padding: 0;
    }
`;

export const Wrapper = styled.div`
    display: flex;
`;

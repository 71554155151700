import { trackEvent, updateTrackingBaseLayer } from "../../../../common-deprecated/utils/tracking";
import { COMPONENT_NAME_MODEL_FILTER } from "../../../../common-deprecated/AnalyticsConstants";

export const updateTrackingLayerVersioning = (): void => {
    updateTrackingBaseLayer({
        page: {
            ...((window.tngaDataLayer?.page as Record<string, unknown>) || {}),
            versions: {
                ...((window.tngaDataLayer?.page?.versions as Record<string, unknown>) || {}),
                "dxp-webcarconfig": commithash,
            },
        },
    });
};

export const trackSelectResult = (modelName: string): void => {
    // model filter only supports new cars for now,
    // workflowName and componentGroup will need to be updated once stock cars are also added
    trackEvent(
        {
            name: "workflowevent",
            component: COMPONENT_NAME_MODEL_FILTER,
            group: "new-cars",
            action: "click-search-results",
            label: `variant:${modelName}`,
        },
        {
            workflow: {
                name: "grade-search-results",
                step: "click-search-results",
                conversion: 1,
            },
        },
    );
};

export const trackSelectModel = (modelName: string): void => {
    // model filter only supports new cars for now,
    // workflowName and componentGroup will need to be updated once stock cars are also added
    trackEvent(
        {
            name: "workflowevent",
            component: COMPONENT_NAME_MODEL_FILTER,
            group: "new-cars",
            action: "click-search-results",
            label: `model:${modelName}`,
        },
        {
            workflow: {
                name: "grade-search-results",
                step: "click-search-results",
                conversion: 1,
            },
        },
    );
};

export const trackOpenFilter = (filterName: string, viaSidebar?: boolean): void => {
    trackEvent({
        component: COMPONENT_NAME_MODEL_FILTER,
        group: "new-cars",
        action: `open-filter-${viaSidebar ? "advanced" : "standard"}`,
        label: filterName,
    });
};

import { createLogic } from "redux-logic";
import { ModelFilterLogicType } from "../store";
import {
    MODEL_FILTER_SELECT_MULTI,
    MODEL_FILTER_SET_CAR_TYPE,
    MODEL_FILTER_SHOW_MORE,
    ModelFilterSelectMultiType,
    ModelFilterSetCarType,
    ModelFilterShowMoreType,
    resetCarType,
    resetMultiFilter,
    showFilter,
    MODEL_FILTER_RESET_CAR_TYPE,
    ModelFilterResetCarType,
    resetSlider,
} from "../actions/ModelFiltersActions";
import { replaceQueryParams } from "../../../../../common-deprecated/History";
import { COMMERCIAL_FILTER_CODE } from "../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { lcvFilterIds } from "../../utils/constants/filterConstants";
import { isRetailer } from "../../../../../common-deprecated/utils";

// Propagate the showMoreFilters state to query.
const showMoreFilterLogic = (createLogic as ModelFilterLogicType<ModelFilterShowMoreType>)({
    type: [MODEL_FILTER_SHOW_MORE],
    process({ getState, action }, dispatch, done) {
        const hideFiltersByDefault = isRetailer(getState().commonSettings);
        if (hideFiltersByDefault) {
            replaceQueryParams({ showMoreFilters: action.show ? "true" : null });
        } else {
            replaceQueryParams({ showMoreFilters: action.show ? null : "false" });
        }
        done();
    },
});

const setCarTypeFilterLogic = (createLogic as ModelFilterLogicType<ModelFilterSetCarType>)({
    type: [MODEL_FILTER_SET_CAR_TYPE],
    process({ getState, action }, dispatch, done) {
        const { filterId, selected, valueIds } = action;
        // Technically we should be able to do this before we actually execute the action, preventing a unnecessary rerender.
        // Leaving it like this for now as the impact should be minimal and the code would be come quite a bit more complex.
        const { modelFilters } = getState();
        const { carType, fuelType } = modelFilters[filterId];

        // If all used car type filters are empty, reset the state.
        if (!carType.find((type) => type.selected) && !fuelType.find((type) => type.selected)) {
            dispatch(resetCarType(filterId));
        }

        // LCV toggle when commercial carType is set
        const selectedValue = carType.find((filter) => valueIds.includes(filter.id));
        if (selectedValue?.externalIdentifier === COMMERCIAL_FILTER_CODE) {
            lcvFilterIds.forEach((id) => {
                if (!selected) dispatch(resetSlider(id));
                if (modelFilters[id].label) dispatch(showFilter(selected, id));
            });
        }

        done();
    },
});

const resetCarTypeFilterLogic = (createLogic as ModelFilterLogicType<ModelFilterResetCarType>)({
    type: [MODEL_FILTER_RESET_CAR_TYPE],
    process(_, dispatch, done) {
        lcvFilterIds.forEach((id) => {
            dispatch(resetSlider(id));
            dispatch(showFilter(false, id));
        });
        done();
    },
});

// Logic related to multiple choice selecting.
const multipleChoiceLogic = (createLogic as ModelFilterLogicType<ModelFilterSelectMultiType>)({
    type: MODEL_FILTER_SELECT_MULTI,

    // eslint-disable-next-line consistent-return
    validate({ getState, action }, next) {
        const { filterId, selected, ids } = action;
        const filter = getState().modelFilters[filterId];

        if (!selected) {
            // If values are deselected, check if there are any other selected values.
            // If not, reset the filter.
            const noValuesActive = !filter.values.find((val) => val.selected && !ids.includes(val.id));
            if (noValuesActive && filter.active) return next(resetMultiFilter(filterId));
        }

        next(action);
    },
});

const modelFilterLogic = [setCarTypeFilterLogic, resetCarTypeFilterLogic, multipleChoiceLogic, showMoreFilterLogic];
export default modelFilterLogic;

import React from "react";
import { LayoutProps } from "styled-system";
import { useModelFilterSelector } from "../../../redux/store";
import * as Styles from "./styles/ModelResultStyles";
import { ModelFilterLabel } from "../../../utils/labels";
import { ModelResultType } from "../../../../../../shared-logic/features/filters/utils/constants/resultConstants";
import { SrOnly } from "../../../../../../common-deprecated/styles/v2/toyota/utilities/ScreenreaderOnly";
import Icon from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { trackSelectModel, trackSelectResult } from "../../../utils/tracking";
import { getModelMapValueById, isRTL } from "../../../../../../common-deprecated/settings/utils/commonSettingUtils";
import { getModelResultImage } from "../../../utils/helpers";
import { ImageFluid } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Image";
import { getBuildUrlBasedOnNewCarResult } from "../../../../../../shared-logic/features/filters/utils/helpers";
import { getGrLogo, redirect } from "../../../../../../common-deprecated/utils";
import { GrCode } from "../../../../../../common-deprecated/constants";
import GRLogo from "../../../../../../../assets/toyota/svg/GR/TME-GR.svg";
import GRLogoSport from "../../../../../../../assets/toyota/svg/GR/TME-GR-sport-black.svg";
import ModelResultPrice from "./price/ModelResultPrice";
import { showPrice } from "../../../../../../common-deprecated/settings/utils/financeSettingUtilsCommon";
import EcoBonusPromotion from "../../../../../../common-deprecated/components/aem/EcoBonusPromotion";
import { getEcoBonusPromotions } from "../../../../../../common-deprecated/utils/promotionUtils";
import { EcoTagType, NewCarResultPromotionType } from "../../../../../../common-deprecated/types/CommonTypes";
import ButtonTertiary from "../../../../../../common-deprecated/components/aem/toyota/ButtonTertiary";
import AvailabilityBadges from "../../../../../../common-deprecated/components/aem/toyota/availability-badges/AvailabilityBadges";
import ButtonQuaternary from "../../../../../../common-deprecated/components/aem/toyota/ButtonQuaternary";
import { Ellipsis } from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Ellipsis";
import EcoTags from "./eco-tags/EcoTags";

type PropsType = {
    model: ModelResultType;
    hasRightBorder?: boolean;
};

const ModelResult = ({ model, hasRightBorder }: PropsType & LayoutProps): JSX.Element => {
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);
    const retailerSettings = useModelFilterSelector((state) => state.retailerSettings);
    const { isStandalone } = commonSettings;
    const { id, name, cars, cheapestCar = cars[0], promotions, availableForBusinessLeasing } = model;

    const ecoTags = new Set(
        cars.filter((car) => Boolean(car.grade.ecoTag)).map((car) => car.grade.ecoTag as EcoTagType),
    ); // We check all the cars of the model and filter out the distinct ecoTags.

    const { links } = getModelMapValueById(commonSettings, id) || {};
    const grLogoCheck = getGrLogo(name, "");

    const shouldShowPrice = showPrice(commonSettings);

    // IMPORTANT -> For this CCIS image there are some caveats to take into accounts, see the comment on the getCarImageVisualForModel method.
    const carImage = getModelResultImage(commonSettings, model, 4, {
        type: "png",
        width: 390,
        height: 260,
        // This is providing negative padding (top) in order to force the returned dimensions of the image to match the designs.
        // No current issues are known using this method, however I would advise to use this with caution.
        padding: "0,0,-50,30",
        scaleMode: 3,
    });

    // URL for standalone link to build and buy
    const standaloneBuildUrl = getBuildUrlBasedOnNewCarResult(cheapestCar, commonSettings, retailerSettings);
    const modelPageUrl = (isStandalone ? standaloneBuildUrl : links?.modelPage) || "";
    const buildPageUrl = (isStandalone ? standaloneBuildUrl : links?.buildPage) || "";

    // If the model has one/more then one grade that is availableForOnlineSales this needs to be shown.
    const modelIsAvailableForOnlineSale = cars.filter((car) => car.grade.availableForOnlineSales).length > 0;
    const modelIsAvailableForOnlineLeasing = cars.filter((car) => car.grade.availableForOnlineLeasing).length > 0;
    // if all cars are sold out, the model is sold out
    const modelIsSoldOut = cars.filter((car) => !car.soldOut).length === 0;

    const ecoBonus = promotions ? getEcoBonusPromotions<NewCarResultPromotionType>(promotions, promotions) : [];

    return (
        <Styles.Wrapper hasRightBorder={hasRightBorder}>
            <Styles.HeaderWrapper role="button" data-url={modelPageUrl}>
                <Styles.Header>
                    <Styles.Title>
                        <Styles.RedirectWrapper
                            onClick={(event) => {
                                event.preventDefault();
                                redirect(modelPageUrl);
                                trackSelectModel(name);
                            }}
                            href={modelPageUrl}
                            data-url={modelPageUrl}
                            aria-labelledby={`viewmodel-${id}`}
                            data-testid={`viewmodel-${id}`}
                        >
                            {name}
                        </Styles.RedirectWrapper>
                    </Styles.Title>

                    {shouldShowPrice && <ModelResultPrice model={model} />}
                    <EcoBonusPromotion ecoBonus={ecoBonus} />

                    {grLogoCheck && (
                        <Styles.GRLogo>{grLogoCheck === GrCode.Gr ? <GRLogo /> : <GRLogoSport />}</Styles.GRLogo>
                    )}

                    <Styles.BadgesWrapper>
                        <EcoTags ecoTags={Array.from(ecoTags)} />
                        <AvailabilityBadges
                            isSoldOut={modelIsSoldOut}
                            isAvailableForOnlineSales={modelIsAvailableForOnlineSale}
                            isAvailableForOnlineLeasing={modelIsAvailableForOnlineLeasing}
                            isAvailableForBusinessLeasing={availableForBusinessLeasing}
                        />
                    </Styles.BadgesWrapper>
                </Styles.Header>
                <Styles.ImageWrapper>
                    <Styles.RedirectWrapper
                        onClick={(event) => {
                            event.preventDefault();
                            redirect(modelPageUrl);
                            trackSelectModel(name);
                        }}
                        href={modelPageUrl}
                    >
                        <ImageFluid
                            src={carImage}
                            width={300}
                            alt={`${name} ${cheapestCar.grade.name} ${cheapestCar.bodyType.name}`}
                            loading="lazy"
                        />
                    </Styles.RedirectWrapper>
                </Styles.ImageWrapper>
            </Styles.HeaderWrapper>
            <Styles.Actions>
                <ButtonTertiary
                    onClick={(event) => {
                        event.preventDefault();
                        redirect(modelPageUrl);
                        trackSelectModel(name);
                    }}
                    href={modelPageUrl}
                    data-url={modelPageUrl}
                    aria-labelledby={`viewmodel-${id}`}
                    data-testid={`viewmodel-${id}`}
                >
                    <SrOnly id={`viewmodel-${id}`}>{name}:</SrOnly>
                    <ModelFilterLabel label="viewModel" />
                </ButtonTertiary>
                <ButtonQuaternary
                    onClick={(event) => {
                        event.preventDefault();
                        redirect(buildPageUrl);
                        trackSelectResult(name);
                    }}
                    href={buildPageUrl}
                    hasIconRight
                    data-url={buildPageUrl}
                    aria-labelledby={`viewgrades-${id}`}
                >
                    <Icon variant={isRTL(commonSettings) ? "arrowLeft" : "arrowRight"} />
                    <Ellipsis maxWidth="100%">
                        <SrOnly>{name}:</SrOnly>
                        <ModelFilterLabel label="goToBuild" />
                    </Ellipsis>
                </ButtonQuaternary>
            </Styles.Actions>
        </Styles.Wrapper>
    );
};

export default ModelResult;

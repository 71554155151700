import { CommonSettingsType } from "../settings/fetchCommonSettings";
import { FinanceInfoType, PriceType } from "../types/CommonTypes";
import { formatMonthlyPrice, FormatMonthlyPriceType, MonthlyFinanceInfoLabelKey } from "../priceUtils";
import { formatPrice } from "../Globalize";

/**
 * Formats financing monthly prices, ideally this should not be used for anything else (like insurance in the past)
 */
export const formatMonthlyFinancePrice = (
    price: PriceType,
    financeInfo: FinanceInfoType,
    commonSettings: CommonSettingsType,
    priceFormat: string,
    rateWithoutPromotions?: number,
): FormatMonthlyPriceType => {
    const term = financeInfo.term || financeInfo.duration || "";
    const tan = financeInfo.annualInterestRate || financeInfo.interestRate;
    const financeInfoReplacements: Record<MonthlyFinanceInfoLabelKey, string> = {
        term,
        productName: financeInfo.productName || "",
        taeg: financeInfo.effectiveInterestRate || "",
        tan: tan || "",
        // residualValue and downPayment are price values, so apply formatPrice as well.
        residualValue: financeInfo.residualValue
            ? formatPrice(financeInfo.residualValue, commonSettings.culture.name, true)
            : "",
        downPayment: financeInfo.downPayment
            ? formatPrice(financeInfo.downPayment, commonSettings.culture.name, true)
            : "",
        totalAmountFinanced: financeInfo.totalAmountFinanced
            ? formatPrice(financeInfo.totalAmountFinanced, commonSettings.culture.name, true)
            : "",
        amountFinanced: financeInfo.amountFinanced
            ? formatPrice(financeInfo.amountFinanced, commonSettings.culture.name, true)
            : "",
        totalCreditWithFees: financeInfo.totalCreditCostWithFee
            ? formatPrice(financeInfo.totalCreditCostWithFee, commonSettings.culture.name, true)
            : "",
        mileage: financeInfo.mileage || "",
        lastInstalment: financeInfo.lastInstalment || "",
        installments: financeInfo.installments || "",
        disclaimer: "", // This is supported for USC components but not for NC for now.
        // Used in USC but not NC
        margin: "",
        euriborDate: "",
        euriborValue: "",
        euriborMonths: "",
    };

    return formatMonthlyPrice(
        price,
        financeInfo,
        commonSettings,
        priceFormat,
        financeInfoReplacements,
        rateWithoutPromotions,
    );
};

export const getEcoBonusPromotions = <T extends { id: string; ecoBonus?: boolean }>(
    availablePromotions: T[],
    applicablePromotions: T[],
): T[] => {
    return applicablePromotions.reduce((prevPromo, applicablePromo) => {
        const promotion = availablePromotions.find(
            (availablePromotion) => availablePromotion.id === applicablePromo.id,
        );

        if (promotion && promotion.ecoBonus) return [...prevPromo, applicablePromo];

        return prevPromo;
    }, [] as T[]);
};

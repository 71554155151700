import React, { Fragment, SyntheticEvent } from "react";
import { useDispatch } from "react-redux";
import * as Styles from "./style/ModelResultPriceStyles";
import { MonthlyPriceLabelKey } from "../../../../../../../common-deprecated/priceUtils";
import Icon from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { FinanceInfoType, PriceType } from "../../../../../../../common-deprecated/types/CommonTypes";
import { showModal } from "../../../../../../../common-deprecated/redux/actions/ModalActions";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import {
    showAemMonthlyRates,
    getAemMonthlyRateFormat,
} from "../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { MODAL_FINANCE_DISCLAIMER } from "../../../../../../../common-deprecated/utils/modalConstants";
import { AemFmComponent } from "../../../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { formatMonthlyFinancePrice } from "../../../../../../../common-deprecated/utils/ncPriceUtils";

type ModelResultMonthlyPriceType = {
    price: PriceType;
    financeInfo: FinanceInfoType | null;
    modelCode?: string;
};

const ModelResultMonthlyPrice = (props: ModelResultMonthlyPriceType): JSX.Element | null => {
    const { price, financeInfo, modelCode = "" } = props;
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);

    const monthlyEnabled = showAemMonthlyRates(commonSettings, AemFmComponent.ModelFilter, "new", modelCode);
    if (!monthlyEnabled) return null;

    const monthlyPriceFormat = getAemMonthlyRateFormat(commonSettings, AemFmComponent.ModelFilter, "new", modelCode);
    const financeLabels = financeInfo
        ? formatMonthlyFinancePrice(price, financeInfo, commonSettings, monthlyPriceFormat).labels
        : [];
    const getDisclaimerFn =
        (info: FinanceInfoType) =>
        (event: SyntheticEvent): void => {
            event.preventDefault();
            event.stopPropagation();
            if (!info.disclaimer) return;
            dispatch(
                showModal({
                    type: MODAL_FINANCE_DISCLAIMER,
                    disclaimer: info.disclaimer,
                    productName: info.productName || "",
                }),
            );
        };

    return (
        <>
            <Styles.MonthlyWrapper>
                {financeLabels.map(({ key, value }) => (
                    <Fragment key={key}>
                        {key === MonthlyPriceLabelKey.Price ? (
                            <Styles.Monthly
                                onClick={financeInfo?.disclaimer ? getDisclaimerFn(financeInfo) : undefined}
                            >
                                <span dangerouslySetInnerHTML={{ __html: value }} />
                                &nbsp;
                                {!!financeInfo?.disclaimer && (
                                    <Styles.MonthlyPriceDisclaimer>
                                        <Styles.DisclaimerIcon>
                                            <Icon variant="info" />
                                        </Styles.DisclaimerIcon>
                                    </Styles.MonthlyPriceDisclaimer>
                                )}
                            </Styles.Monthly>
                        ) : (
                            <Styles.LegalText dangerouslySetInnerHTML={{ __html: value }} />
                        )}
                    </Fragment>
                ))}
            </Styles.MonthlyWrapper>
        </>
    );
};

export default ModelResultMonthlyPrice;

/* eslint-disable react/no-danger */
import React from "react";
import * as Styles from "./style/ModelResultPriceStyles";
import { useModelFilterSelector } from "../../../../redux/store";
import {
    formatAEMCashPriceIsrael,
    getAemPriceConfig,
} from "../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import Popover from "../../../../../../../common-deprecated/components/popover/aem/Popover";
import { ModelResultCashPriceType } from "./ModelResultCashPrice";

const ModelResultCashPriceIsrael = (
    props: ModelResultCashPriceType & { cashPriceDisclaimer?: string },
): JSX.Element | null => {
    const { price, modelCode = "", cashPriceDisclaimer } = props;
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);

    const priceConfig = getAemPriceConfig(commonSettings, AemFmComponent.ModelFilter, modelCode);

    if (!priceConfig.priceEnabled) return null;

    const cashFormatted = formatAEMCashPriceIsrael(
        price.discount,
        commonSettings.culture.name,
        priceConfig,
        price.licenseFee,
    );

    return (
        <Styles.PricesWrapper>
            <Styles.Cash>
                {cashFormatted.map((value, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={index}>
                        <Styles.SpanText dangerouslySetInnerHTML={{ __html: value }} />
                        {/* Only render the disclaimer when there are more then 1 span elements and when a disclaimer is available. */}
                        {cashPriceDisclaimer && index === cashFormatted.length - 1 && cashFormatted.length > 1 && (
                            <Popover iconColor="primaryBlack" showOnClick content={cashPriceDisclaimer} useHTML />
                        )}
                    </span>
                ))}
            </Styles.Cash>
        </Styles.PricesWrapper>
    );
};

export default ModelResultCashPriceIsrael;

import React, { useRef, useState } from "react";
import { showPrice } from "../../settings/utils/financeSettingUtilsCommon";
import { useCommonSelector } from "../../redux/commonStore";
import { NewCarResultPromotionType, PricePromotionType } from "../../types/CommonTypes";
import PopoverTooltip from "../popover/aem/PopoverTooltip";
import { CommonLabel, getCommonOptionalLabel } from "../../utils/commonLabels";
import * as Button from "../../styles/v2/toyota/components/Button";
import * as Text from "../../styles/v2/toyota/utilities/Text";
import { IconWrapper } from "../../styles/v2/toyota/globals/IconWrapper";
import Icon from "../../styles/v2/toyota/globals/Icon";

type EcoBonusPromotionTypes = {
    ecoBonus: (PricePromotionType | NewCarResultPromotionType)[];
};

const EcoBonusPromotion = ({ ecoBonus }: EcoBonusPromotionTypes): null | JSX.Element => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const iconRef = useRef<HTMLButtonElement>(null);
    const ecoBonusTooltip = getCommonOptionalLabel({ commonSettings }, "ecoBonusTooltip");

    if (!showPrice(commonSettings) || ecoBonus.length <= 0 || !ecoBonusTooltip) return null;

    const onClose = (): void => setShowTooltip(false);

    return (
        <>
            <Button.ButtonWithIcon hasSmallText mt={1}>
                <IconWrapper>
                    <Icon variant="tree" />
                </IconWrapper>
                <Button.Link
                    ref={iconRef}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <Text.Button>
                        <CommonLabel label="ecoBonusLabel" />
                    </Text.Button>
                </Button.Link>
            </Button.ButtonWithIcon>
            <PopoverTooltip
                hide={!showTooltip}
                onClose={onClose}
                iconRef={iconRef}
                content={ecoBonusTooltip}
                useHTML
                openedOnHover
            />
        </>
    );
};

export default EcoBonusPromotion;

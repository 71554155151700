import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { ModelResultType } from "../../../../shared-logic/features/filters/utils/constants/resultConstants";
import {
    getCarImageFromCardb,
    isCod,
    isMasterOrSlave,
} from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { AssetSizeType } from "../../../../common-deprecated/types/CommonTypes";
import {
    getCarImageVisualForModel,
    ImageOptionsType,
} from "../../../../common-deprecated/settings/utils/ccisSettingUtils";

export const getModelResultImage = (
    commonSettings: CommonSettingsType,
    model: ModelResultType,
    frame: number,
    imageOptions: ImageOptionsType,
): string => {
    const { id, assets } = model;

    const carDbImage = getCarImageFromCardb(commonSettings, assets || [], AssetSizeType.BIG, 432, 190);
    const ccisImage = getCarImageVisualForModel(commonSettings, "exterior", frame, id, imageOptions);

    return carDbImage && !commonSettings.query.forceCCIS ? carDbImage : ccisImage.src;
};

export const showComingSoon = (commonSettings: CommonSettingsType, results: ModelResultType[]): boolean => {
    const { isStandalone } = commonSettings.query;
    return !isCod(commonSettings) && !isMasterOrSlave(commonSettings) && !isStandalone && results.length > 0;
};

import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { ModelFiltersReducerType } from "../redux/reducers/ModelFiltersReducer";
import { getAPI } from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { getAemExclVATModelIds } from "../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { ModelFilterDispatchType } from "../redux/store";
import {
    resetCarType,
    resetMultiFilter,
    resetPriceFilter,
    resetSlider,
    setActiveFilters,
} from "../redux/actions/ModelFiltersActions";
import {
    minMaxSliderModelFilterIds,
    MinMaxSliderModelFilterIdType,
    ModelFilterId,
    ModelFilterSortOrder,
    multipleChoiceModelFilterIds,
    MultipleChoiceModelFilterIdType,
} from "./constants/filterConstants";
import {
    PriceFilterConfigType,
    CarTypeFilterEnum,
} from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { getMinMaxQueryValue, getSelectedCarTypes } from "../../../../shared-logic/features/filters/utils/filters";
import { ModelFilterRequestBodyType, ModelFilterRequestReturnType } from "./constants/filterRequestConstants";
import {
    getCarTypeValues,
    getMCSentenceLabel,
    getMinMaxSentenceLabel,
    LabelReturnType,
} from "../../../../shared-logic/features/filters/utils/filterLabelUtils";
import { AemFmComponent } from "../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { formatPrice } from "../../../../common-deprecated/Globalize";
import { FinanceOptionType } from "../../../../common-deprecated/types/CommonTypes";

export const isMinMaxSlider = (filterId: ModelFilterId): filterId is MinMaxSliderModelFilterIdType =>
    minMaxSliderModelFilterIds.includes(filterId as MinMaxSliderModelFilterIdType);

export const isMultipleChoice = (filterId: ModelFilterId): filterId is MultipleChoiceModelFilterIdType =>
    multipleChoiceModelFilterIds.includes(filterId as MultipleChoiceModelFilterIdType);

export const isPrice = (filterId: ModelFilterId): filterId is ModelFilterId.Price => filterId === ModelFilterId.Price;

export const isCarType = (filterId: ModelFilterId): filterId is ModelFilterId.CarType =>
    filterId === ModelFilterId.CarType;

export const getModelResultBody = (
    filters: ModelFiltersReducerType,
    sortOrder: ModelFilterSortOrder,
    exclVATModelIds: string[],
): ModelFilterRequestBodyType => {
    const data: ModelFilterRequestBodyType = {
        filters: { multi: [], minMax: [] },
        sortOrder,
        exclVATModelIds,
    };

    Object.values(ModelFilterId)
        .filter((filterId) => filters[filterId]?.active)
        .forEach((filterId) => {
            if (!data.filters) return;

            if (isCarType(filterId)) {
                const { models, fuelType, carType } = getSelectedCarTypes(filters[filterId]!, "id");

                if (models.length) data.filters.multi.push({ filterId: CarTypeFilterEnum.Models, valueIds: models });
                if (carType.length) data.filters.multi.push({ filterId: CarTypeFilterEnum.CarType, valueIds: carType });
                if (fuelType.length)
                    data.filters.multi.push({ filterId: CarTypeFilterEnum.FuelType, valueIds: fuelType });
            } else if (isPrice(filterId)) {
                if (filters[filterId]?.cash.active) {
                    data.filters.minMax.push({
                        filterId: "cash",
                        ...getMinMaxQueryValue(filters[filterId]!.cash),
                    });
                }
                if (filters[filterId]?.monthly.active) {
                    data.filters.minMax.push({
                        filterId: "monthly",
                        ...getMinMaxQueryValue(filters[filterId]!.monthly),
                    });
                }
            } else if (isMultipleChoice(filterId)) {
                const valueIds = filters[filterId]?.values
                    .filter((filterValue) => filterValue.selected)
                    .map((filterValue) => filterValue.id);
                if (valueIds?.length) data.filters.multi.push({ filterId, valueIds });
            } else if (isMinMaxSlider(filterId)) {
                data.filters.minMax.push({ filterId, ...getMinMaxQueryValue(filters[filterId]!) });
            }
        });

    return data;
};

export const fetchModelResults = async (
    commonSettings: CommonSettingsType,
    modelFilters: ModelFiltersReducerType,
    sortOrder: ModelFilterSortOrder,
): Promise<ModelFilterRequestReturnType | null> => {
    const newCarResultApi = getAPI(commonSettings, "filters-model/results");
    const exclVATModelIds = getAemExclVATModelIds(commonSettings, AemFmComponent.ModelFilter);

    const body = getModelResultBody(modelFilters, sortOrder, exclVATModelIds);

    const fetchResult = await fetch(newCarResultApi, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        referrer: "no-referrer",
        body: JSON.stringify(body),
    });

    return fetchResult.status === 200 ? fetchResult.json() : null;
};

/**
 * Helper function to reset filters
 */
export const getFilterReset = (
    filterId: ModelFilterId,
    dispatch: ModelFilterDispatchType,
    filter?: CarTypeFilterEnum,
): void => {
    if (filterId === ModelFilterId.CarType) {
        dispatch(resetCarType(filterId, filter));
    }

    if (filterId === ModelFilterId.Price) {
        dispatch(resetPriceFilter(filterId));
    }

    if (isMinMaxSlider(filterId)) {
        dispatch(resetSlider(filterId));
    }

    if (isMultipleChoice(filterId)) {
        dispatch(resetMultiFilter(filterId));
    }
};

/**
 * Helper function to clear all the active filters in the car filter
 */
export const clearAllFilters = (dispatch: ModelFilterDispatchType, filters: ModelFiltersReducerType): void => {
    const filterIds = Object.values(ModelFilterId);
    const activeFilters = filterIds.filter((filterId) => filters[filterId].active);
    activeFilters.forEach((filterId) => {
        getFilterReset(filterId, dispatch);
    });
    dispatch(setActiveFilters([]));
};

export const getModelFilterPriceSentenceLabel = (
    filter: PriceFilterConfigType,
    financeOption: FinanceOptionType,
    cultureName: string,
): LabelReturnType => {
    const { cash, monthly, sentenceConfig } = filter;
    const { finalValueSeparator, valueSeparator, valueLabel } = sentenceConfig;

    const { currentMinValue, currentMaxValue, customMinValue, customMaxValue } =
        financeOption === "monthly" ? monthly : cash;

    const minValueString = String(formatPrice(Math.max(currentMinValue, customMinValue), cultureName));
    const maxValueString = String(
        formatPrice(Math.min(currentMaxValue, customMaxValue > 0 ? customMaxValue : currentMaxValue), cultureName),
    );

    // Legacy handling.
    if (valueLabel.includes("{value}")) {
        const minValue = valueLabel.replace("{value}", minValueString);
        const maxValue = valueLabel.replace("{value}", maxValueString);

        const separator = finalValueSeparator || valueSeparator;
        return [{ text: `${minValue}${separator}${maxValue}`, strikethrough: false }];
    }

    // Min-max handling (Used in Model Filter)
    return [
        { text: valueLabel.replace("{min}", minValueString).replace("{max}", maxValueString), strikethrough: false },
    ];
};

/**
 * Helper to get labels for model filter values (the literal filters, not the component as a whole)
 */
export const getModelFilterLabels = (
    filterId: ModelFilterId,
    filters: ModelFiltersReducerType,
    cultureName: string,
): { midLabel: LabelReturnType; filterCount: number } => {
    const { anyLabel } = filters[filterId]!.sentenceConfig;
    let midLabel: LabelReturnType = [{ text: anyLabel, strikethrough: false }];
    let filterCount = 0;
    if (isCarType(filterId)) {
        const carTypeFilter = filters[filterId]!;
        if (carTypeFilter.active) ({ filterCount, midLabel } = getCarTypeValues(carTypeFilter, true));
    }
    if (filterId === ModelFilterId.Price && filters[filterId]!.active) {
        const activeFinanceOption = filters[filterId]!.cash.active ? "cash" : "monthly";
        midLabel = getModelFilterPriceSentenceLabel(filters[filterId]!, activeFinanceOption, cultureName);
        filterCount = 1;
    }

    if (isMinMaxSlider(filterId)) {
        midLabel = getMinMaxSentenceLabel(filters[filterId]!);
        if (filters[filterId]!.active) filterCount = 1;
    }

    if (isMultipleChoice(filterId)) {
        midLabel = getMCSentenceLabel(filters[filterId]!.values, filters[filterId]!.sentenceConfig, true);
        filterCount = filters[filterId]!.values.filter((value) => value.selected).length;
    }
    return { midLabel, filterCount };
};

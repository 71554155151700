import React, { FC } from "react";
import { IconCalculator, IconCartLeasing, IconCartNegative } from "../../../../styles/v2/toyota/globals/Icon";
import Badge from "../badge/Badge";
import { useCommonOptionalLabel } from "../../../../utils/commonLabels";
import * as Styles from "./styles/AvailabilityBadgesStyles";
import { OutlinePill } from "../../../../styles/v2/toyota/components/Pill";
import LogoKinto from "../../../../../../assets/toyota/svg/logo-kinto.svg";

const AvailabilityBadges: FC<{
    isSoldOut?: boolean;
    isAvailableForOnlineSales?: boolean;
    isAvailableForOnlineLeasing?: boolean;
    isAvailableForBusinessLeasing?: boolean;
}> = ({ isSoldOut, isAvailableForOnlineSales, isAvailableForOnlineLeasing, isAvailableForBusinessLeasing }) => {
    const [soldOutLabel, availableForOnlineSaleLabel, availableForOnlineLeasingLabel] = useCommonOptionalLabel([
        "soldOut",
        "availableForOnlineSale",
        "availableForOnlineLeasing",
    ]);
    const showIsSoldOut = isSoldOut && soldOutLabel;
    const showIsAvailableForOnlineSales = isAvailableForOnlineSales && availableForOnlineSaleLabel;
    const showIsAvailableForOnlineLeasing = isAvailableForOnlineLeasing && availableForOnlineLeasingLabel;

    if (
        !showIsSoldOut &&
        !showIsAvailableForOnlineSales &&
        !showIsAvailableForOnlineLeasing &&
        !isAvailableForBusinessLeasing
    )
        return null;

    return (
        <Styles.AvailabilityBadges>
            <Styles.List role="list">
                {showIsSoldOut ? (
                    <li>
                        <Badge
                            bgColor="danger"
                            color="dangerDark"
                            icon={<IconCartNegative width="20" height="20" viewBox="0 -2 20 20" />}
                        >
                            {soldOutLabel}
                        </Badge>
                    </li>
                ) : (
                    <>
                        {showIsAvailableForOnlineSales && (
                            <li>
                                <Badge
                                    bgColor="successLight"
                                    color="successDark"
                                    icon={<IconCartLeasing width="20" height="20" viewBox="0 0 40 40" />}
                                >
                                    {availableForOnlineSaleLabel}
                                </Badge>
                            </li>
                        )}
                        {showIsAvailableForOnlineLeasing && (
                            <li>
                                <Badge
                                    bgColor="grey2"
                                    color="primaryBlack"
                                    icon={<IconCalculator width="20" height="20" viewBox="-2 -1 16 16" />}
                                >
                                    {availableForOnlineLeasingLabel}
                                </Badge>
                            </li>
                        )}
                        {isAvailableForBusinessLeasing && (
                            <li>
                                <OutlinePill>
                                    <LogoKinto width="54" height="13" viewBox="0 0 481 124" />
                                </OutlinePill>
                            </li>
                        )}
                    </>
                )}
            </Styles.List>
        </Styles.AvailabilityBadges>
    );
};

export default AvailabilityBadges;
